import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLocation } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function HotelDetail ({ onLogout }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  var endpoint = ApiEndPoint()
  const apitoken = Hotelapitoken()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [showMoreFacilities, setShowMoreFacilities] = useState(10)
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('SAR')
  const [counter, setCounter] = useState(0)
  const searchtype=sessionStorage.getItem('HotelSearchType');
  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])
  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  const seleectedhotelfromlist2 = useSelector(
    state => state.hotels.hotels.hotels_list
  )
  const seleectedhotelfromlist =seleectedhotelfromlist2.filter(item=>item.hotel_id===hoteldetail.hotel_code)
  let { id } = useParams()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }

  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {

  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    const selectedroom = hoteldetail.rooms_options[index]
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].adult === selectedroom.adults &&
        result[i].child === selectedroom.childs &&
        result[i].qty === selectedroom.rooms_qty
      ) {
        if (result[i].status === false) {
          const key = { rateKey: selectedroom.booking_req_id, index: index }
          const newSelectedRoom = {
            room_rate_key: JSON.stringify(key),
            rooms_qty: selectedroom.rooms_qty,
            index: index
          }
          result[i].status = true
          setSelectedRooms([...selectedRooms, newSelectedRoom])
          setHotelSelectedRooms([...hotelSelectedRooms, key])
        } else {
          if (selectedRooms.some(room => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
              room => room.index !== index
            )
            const newroom = hotelSelectedRooms.filter(
              item => item.index !== index
            )
            setSelectedRooms(updatedSelectedRooms)
            setHotelSelectedRooms(newroom)
            result[i].status = false
          } else {
            toast.error('Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }
      }
    }
  }
  const HandleMoreFacilities = () => {
    setShowMoreFacilities(showMoreFacilities + 10)
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        )
        return
      }

      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }

      const finaldata = {
        token: apitoken,
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist[0]),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability_Live',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )
        // Handle the API response here
        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if (response.data !== '') {
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const renderPrice = (price,roomcurrency) => {
    var checkstatus= localStorage.getItem('checkapiCurr');
    var allcurr= JSON.parse(localStorage.getItem('HotelListCurrency'));
    var selectedcurrency1=localStorage.getItem('selectedCurrency');
    var sarrates= JSON.parse(localStorage.getItem('SarCurrencyRates'));
    var userData ;
    const hotelCurrency1 = localStorage.getItem('HotelCurrency');
    var Rates 
    if (hotelCurrency1 !== null) {
      userData = JSON.parse(localStorage.getItem('HotelCurrency'));
      Rates=userData;
      // Do something with the value
    } else {
      userData=allcurr.filter(item=> item.base_code!=='SAR')
      Rates=userData[0];
    }
    if (checkstatus!=='null') {
      var newcurrencysar=localStorage.getItem('selectedCurrencyNew');
      if(roomcurrency!==newcurrencysar || selectedcurrency1 !=='SAR'){
        if (CurrencyRates === undefined) {
          if(roomcurrency==='SAR'){
            const gbpprice = sarrates.conversion_rates[selectedcurrency1] // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price))
          }else{
            const gbpprice = Rates.conversion_rates[baseCName] // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price))
          }
          
        } else {
          var select123 = CurrencyRates.selectedcurrency
          if(roomcurrency==='SAR'){
            const gbpprice = sarrates.conversion_rates[selectedcurrency1]; // Use square brackets to access the property
            var baseprice1 = (Number(gbpprice) * Number(price));
            var baseprice =baseprice1;
          }else{
            const gbpprice =  Rates.conversion_rates[baseCName];
            var baseprice1 = (Number(gbpprice) * Number(price));
            const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] ;// Use square brackets to access the property
            var baseprice = (Number(gbpprice2) * Number(baseprice1));
          }
        }
        if (Number(baseprice) % 1 !== 0) {
          return Number(baseprice).toFixed(2);
        }
        return baseprice;
      }
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
     return price;
    } else {
      setShowPrice(false)
    }
  }
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      hoteldetail.admin_markup=2;
      hoteldetail.admin_markup_type= 'Percentage';
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2);
      }
      return finalpricemarkup
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout logout={onLogout}>
          <div className='uitk-spacing uitk-spacing-margin-medium-inline-six'>
            <ToastContainer />
            <div style={{ marginTop: '1px', backgroundColor: 'white' }}>
              
              <Images data={hoteldetail.hotel_gallery} />
              <div
                class='uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme'
                data-stid='uitk-card-content-section-navbar'
                style={{ position: 'sticky' }}
              >
                <div class='uitk-tabs-container'>
                  <ul
                    class='uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border'
                    role='list'
                  >
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-1' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-1'
                        onClick={() => handleButtonClick2('section-1')}
                        class='uitk-tab-anchor'
                        aria-current='true'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Overview</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-3' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-3'
                        onClick={() => handleButtonClick('section-3')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Amenities</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-2' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-2'
                        onClick={() => handleButtonClick2('section-2')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Rooms</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    {/* <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                    <a  href='#section-4' onClick={() => handleButtonClick('section-4')}  className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Near By Place</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li> */}
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-5' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-5'
                        onClick={() => handleButtonClick('section-5')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Policies</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-6' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-6'
                        onClick={() => handleButtonClick('section-6')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Review & Rating</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    {/* <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li> */}
                  </ul>
                </div>
                <div class='uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1 reserve-room'>
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing'>
                    <div class='uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three'>
                      <div data-stid='navbar-quick-reserve-button'>
                        {/* <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Reserve a room</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid mt-2 rounded-3'>
              <section
                id='section-1'
                className='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-spacing uitk-flat-border-top uitk-spacing-margin-blockend-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme'
              >
                <h3>{hoteldetail.hotel_name}</h3>
                <div className='row'>
                  <div className=''>
                    <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                      <FontAwesomeIcon
                        style={{ color: '#63ab45' }}
                        icon={faLocation}
                      />
                    </i>{' '}
                    {hoteldetail.hotel_address}
                    <div className='row mt-2 mb-2 p-2'>
                      {/* <h6 className='mb-2'>Popular Amenities</h6> */}
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Stars Rating
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {hoteldetail.stars_rating}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Minimum Stay
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {daysBetween} Night
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check In
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {moment(ReduxSearchData.check_in).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check Out
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {moment(ReduxSearchData.check_out).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Country
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {hoteldetail.hotel_country}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              City
                            </p>
                            <p
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {hoteldetail.hotel_city}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Boards
                            </p>
                            {hoteldetail.hotel_boards.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0'
                                style={{ fontSize: '12px' }}
                                class='title '
                              >
                                {item.board_name}{' '}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-4 mt-1'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i
                              style={{ color: '#63ab45' }}
                              class='fas fa-check'
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Segments
                            </p>
                            {hoteldetail.hotel_segments.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0'
                                style={{ fontSize: '12px' }}
                                class='title '
                              >
                                {item}{' '}
                              </span>
                            ))}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className='mb-2'>Explore the area</h5>
                    <div style={{ width: '100%' }}>
                      <iframe
                        width='100%'
                        height='250'
                        frameborder='0'
                        scrolling='no'
                        marginheight='0'
                        marginwidth='0'
                        src={mapUrl}
                        className='rounded-4'
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div>
                  <div class='lazyload-wrapper uitk-spacing uitk-spacing-margin-block-six'>
                    <div class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme'>
                      <section>
                        <div class='uitk-card-content-section'>
                          <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three about-uit'>
                            <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large sub-about1'>
                              <div class='uitk-layout-flex'>
                                <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                  <h3
                                    class='uitk-heading uitk-heading-4'
                                    tabindex='-1'
                                  >
                                    About this property
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about'>
                              <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three sub-about-desc'>
                                <div
                                  data-stid='content-item'
                                  itemprop='description'
                                  class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'
                                >
                                  <div class='uitk-layout-flex'>
                                    <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                      <h3
                                        class='uitk-heading uitk-heading-5'
                                        tabindex='-1'
                                      >
                                        {hoteldetail.hotel_name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div class='uitk-expando-peek'>
                                    <div class='uitk-expando-peek-main uitk-expando-peek-main-active uitk-expando-peek-main-whitespace'>
                                      <div class='uitk-expando-peek-inner display-lines'>
                                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid'>
                                          <div class='uitk-layout-grid-item'>
                                            <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                              <div data-stid='content-markup'>
                                                <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                  {hoteldetail.hotel_address}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class='uitk-layout-grid-item'>
                                            <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                              <div data-stid='content-markup'>
                                                <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                  {hoteldetail.description}{' '}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class='uitk-layout-grid-item'>
                                            <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                              <div data-stid='content-markup'>
                                                <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                  Other facilities include:{' '}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class='uitk-layout-grid-item'>
                                            <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                              <div data-stid='content-markup'>
                                                <ul
                                                  class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two'
                                                  role='list'
                                                >
                                                  {hoteldetail.hotel_facilities
                                                    .slice(0, showMoreFacilities).map((item, index) => (
                                                      <li
                                                        key={index}
                                                        class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent'
                                                        role='listitem'
                                                      >
                                                        <span
                                                          aria-hidden='true'
                                                          class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                                        ></span>
                                                        <span class='uitk-typelist-item-child'>
                                                          {item}
                                                        </span>
                                                      </li>
                                                    ))}
                                                </ul>
                                                {showMoreFacilities <
                                                  hoteldetail.hotel_facilities
                                                    .length && (
                                                  <p
                                                    onClick={
                                                      HandleMoreFacilities
                                                    }
                                                    style={{
                                                      color: 'blue',
                                                      cursor: 'pointer'
                                                    }}
                                                  >
                                                    View More
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              <section id='section-2' className='mt-3'>
                <h2 class='uitk-heading uitk-heading-4 mb-2' tabindex='-1'>
                  Choose Your Room
                </h2>
                <HotelSearch />

                <div className='row'>
                  {hoteldetail.rooms_options.map((item, index) => (
                    <div key={index} className='col-md-4 pt-2 pb-2'>
                      <div
                        class='uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-overflow uitk-card-has-primary-theme'
                        data-stid='property-offer-1'
                      >
                        <div class='uitk-layout-flex-item'>
                          <div>
                            <span>
                              <div class='uitk-gallery-carousel uitk-rounded-border-top'>
                                <div
                                  style={{ height: '15em', overflow: 'hidden' }}
                                >
                                  {item.rooms_images &&
                                  item.rooms_images.length > 0 ? (
                                    <img
                                      className='room-card-image'
                                      src={item.rooms_images[0]}
                                      alt={item.room_name}
                                    />
                                  ) : (
                                    <img src={noImage} alt='Default Image' />
                                  )}
                                </div>
                              </div>
                              {item.room_supplier_code !=='' && (
                              <div class='uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute'>
                                <div data-stid='outlined-save-button'>
                                  <div class='favorite-button-wrapper'>
                                    <button
                                      type='button'
                                      aria-label='Save Swissôtel Al Maqam Makkah to a trip'
                                      class='favorite-button favorite-button-button-no-label'
                                    >
                                     {item.room_supplier_code}
                                    </button>
                                  </div>
                                </div>
                              </div>
                               )}
                            </span>
                          </div>
                          <div class='uitk-spacing uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-two uitk-spacing-padding-inline-three'>
                            <div class='uitk-spacing uitk-spacing-padding-small-blockend-half'>
                              <h3 class='uitk-heading uitk-heading-6'>
                                {item.room_name} ({item.board_id})
                              </h3>
                            </div>
                            <ul className='room_facility mt-2'>
                              {item.rooms_facilities &&
                              item.rooms_facilities.length > 0 ? (
                                <RoomFacilities item={item} />
                              ) : (
                                <li>No facilities available</li>
                              )}
                            </ul>
                            {item.request_type === '1' && (
                              <div className='room-request'>
                                <h6>Room on Request</h6>
                              </div>
                            )}
                            <div class='d-flex justify-content-between mt-2'>
                              <h6>{item.adults} Adults </h6>
                              <h6> {item.childs} children</h6>
                              <h6> {item.rooms_qty} Rooms</h6>
                            </div>
                            <div
                              class='uitk-layout-grid mt-2 uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-two'
                              data-stid='highlightedMessages-321661323'
                            >
                              <div class='uitk-layout-flex'>
                                <div class='uitk-spacing uitk-spacing-padding-blockstart-half'>
                                  {item.cancliation_policy_arr &&
                                  item.cancliation_policy_arr.length > 0 ? (
                                    new Date(
                                      item.cancliation_policy_arr[0].from_date
                                    ) > todayDate ? (
                                      <button
                                        type='button'
                                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                      >
                                        <div
                                          style={{ color: 'green' }}
                                          class='uitk-text uitk-type-300 uitk-text-default-theme'
                                        >
                                          No cancellation fee before{' '}
                                          {moment(
                                            item.cancliation_policy_arr[0]
                                              .from_date
                                          ).format('DD-MM-YYYY')}{' '}
                                          (property local time)
                                        </div>
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                      >
                                        <div
                                          style={{ color: 'red' }}
                                          class='uitk-text uitk-type-300 uitk-text-default-theme'
                                        >
                                          Non-Refundable
                                          {/* <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg> */}
                                        </div>
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      type='button'
                                      class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                    >
                                      <div
                                        style={{ color: 'red' }}
                                        class='uitk-text uitk-type-300 uitk-text-default-theme'
                                      >
                                        Non-Refundable
                                        {/* <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg> */}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='uitk-card-content-section uitk-layout-flex-item-align-self-stretch uitk-layout-flex-item'>
                          <div class='uitk-spacing uitk-spacing-margin-three'>
                            <div>
                              <div class=' uitk-layout-flex-align-items-flex-end uitk-layout-flex-justify-content-space-between'>
                                <div class='uitk-layout-flex-item'>
                                  {hoteldetail.hotel_provider !=='Custome_hotel' && (
                                  <div>
                                    <div data-stid='price-summary'>
                                      <div
                                        class=' uitk-layout-flex-flex-direction-column'
                                        data-test-id='price-summary'
                                      >
                                        <div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-spacing text-center uitk-spacing-padding-block-half'>
                                              {item.room_Promotions_Exist !=='1'?(
                                              <span aria-hidden='true'>
                                                <div class='uitk-text fw-bold uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  {showPrice ? (
                                                    <p
                                                      className='fw-bold'
                                                      style={{
                                                        color: '#000',
                                                        fontSize: '12px',
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {CurrencyRates ===
                                                      undefined
                                                        ? baseCName
                                                        : CurrencyRates.selectedcurrency}{' '}
                                                      {(
                                                        renderPrice(
                                                          calculateMarkup(
                                                            item.rooms_total_price
                                                          ),hoteldetail.hotel_curreny
                                                        ) / Number(daysBetween)
                                                      ).toFixed(2)}{' '}
                                                      / Night
                                                    </p>
                                                  ) : (
                                                    <p
                                                      className='fw-bold'
                                                      style={{
                                                        color: '#000',
                                                        fontSize: '12px',
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {
                                                        hoteldetail.hotel_curreny
                                                      }{' '}
                                                      {(
                                                        calculateMarkup(
                                                          item.rooms_total_price
                                                        ) / Number(daysBetween)
                                                      ).toFixed(2)}{' '}
                                                      / Night
                                                    </p>
                                                  )}
                                                </div>
                                                <div class='uitk-text fw-bold uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  {showPrice ? (
                                                    <h6
                                                      className='fw-bold'
                                                      style={{
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {CurrencyRates ===
                                                      undefined
                                                        ? baseCName
                                                        : CurrencyRates.selectedcurrency}{' '}
                                                      {renderPrice(
                                                        calculateMarkup(
                                                          item.rooms_total_price
                                                        ),hoteldetail.hotel_curreny
                                                      )}
                                                    </h6>
                                                  ) : (
                                                    <h6
                                                      className='fw-bold'
                                                      style={{
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {hoteldetail.hotel_curreny}{' '}
                                                      {calculateMarkup(
                                                        item.rooms_total_price
                                                      )}
                                                      {/* <sub style={{color:'#808080ad'}}><del>150</del></sub> */}
                                                    </h6>
                                                  )}
                                                  <span
                                                    className='text-center'
                                                    style={{
                                                      color: '#000',
                                                      fontSize: '12px'
                                                    }}
                                                  >
                                                    Price For {daysBetween}{' '}
                                                    Nights
                                                  </span>
                                                </div>
                                              </span>
                                               ):(
                                                <span aria-hidden='true'>
                                                <div class='uitk-text fw-bold uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  {showPrice ? (
                                                    <p
                                                      className='fw-bold'
                                                      style={{
                                                        color: '#000',
                                                        fontSize: '12px',
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {CurrencyRates ===
                                                      undefined
                                                        ? baseCName
                                                        : CurrencyRates.selectedcurrency}{' '}
                                                      {(
                                                        renderPrice(
                                                          calculateMarkup(
                                                            Number(item.room_Promotions.promotion_Rate)                                                           
                                                          ),hoteldetail.hotel_curreny
                                                        ) 
                                                      ).toFixed(2)}{' '}
                                                      / Night
                                                    </p>
                                                  ) : (
                                                    <p
                                                      className='fw-bold'
                                                      style={{
                                                        color: '#000',
                                                        fontSize: '12px',
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {
                                                        hoteldetail.hotel_curreny
                                                      }{' '}
                                                      {(
                                                        calculateMarkup(
                                                          Number(item.room_Promotions.promotion_Rate)
                                                        )
                                                      ).toFixed(2)}{' '}
                                                      / Night
                                                    </p>
                                                  )}
                                                </div>
                                                <div class='uitk-text fw-bold uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  {showPrice ? (
                                                    <h6
                                                      className='fw-bold'
                                                      style={{
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {CurrencyRates ===
                                                      undefined
                                                        ? baseCName
                                                        : CurrencyRates.selectedcurrency}{' '}
                                                      {renderPrice(
                                                        calculateMarkup(
                                                          item.rooms_selling_price_Promotion
                                                        ),hoteldetail.hotel_curreny
                                                      )}
                                                      <sub style={{color:'#808080ad'}}><del>{renderPrice(
                                                        calculateMarkup(
                                                          item.rooms_total_price
                                                        ),hoteldetail.hotel_curreny
                                                      )}</del></sub>
                                                    </h6>
                                                  ) : (
                                                    <h6
                                                      className='fw-bold'
                                                      style={{
                                                        fontFamily: 'sans-serif'
                                                      }}
                                                    >
                                                      {hoteldetail.hotel_curreny}{' '}
                                                      {calculateMarkup(
                                                         item.rooms_selling_price_Promotion
                                                      )}
                                                      <sub style={{color:'#808080ad'}}><del>{calculateMarkup(
                                                         item.rooms_total_price
                                                      )}</del></sub>
                                                    </h6>
                                                  )}
                                                  <span
                                                    className='text-center'
                                                    style={{
                                                      color: '#000',
                                                      fontSize: '12px'
                                                    }}
                                                  >
                                                    Price For {daysBetween}{' '}
                                                    Nights
                                                  </span>
                                                </div>
                                              </span>
                                               )}
                                            </div>
                                          </div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-text text-center uitk-type-start uitk-type-200 uitk-text-default-theme'>
                                              Inclusive of vat and taxes
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class='form-group text-center'
                                      data-toggle='buttons'
                                    >
                                      <label
                                        onClick={event =>
                                          selectedRoomData(index, event)
                                        }
                                        class='btn btn-default mt-2 select-room--checkbox primary'
                                      >
                                        <i class='fa fa-fw'></i>
                                        <input
                                          id={index}
                                          autoComplete='off'
                                          className='room-check'
                                          type='checkbox'
                                          checked={selectedRooms.some(
                                            room => room.index === index
                                          )}
                                          onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                        />
                                        Select Room
                                      </label>
                                    </div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              <div></div>
            </div>
          </div>
        </Layout>
      )}
      {searchtype !=='Custome_hotel' && (
      <div id='mybutton'>
        <button
          onClick={BookRoom}
          class='btn feedback btn-primary fw-medium  search-btn1 mt-2 detail-view-btn'
        >
          Book Now
        </button>
      </div>
      )}
    </>
  )
}

export default HotelDetail
